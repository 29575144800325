module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://admin.syjmb.foundation/graphql","verbose":true,"develop":{"hardCacheMediaFiles":true,"hardCacheData":true,"nodeUpdateInterval":5000},"debug":{"timeBuildSteps":true,"graphql":{"writeQueriesToDisk":true,"showQueryVarsOnError":true,"showQueryOnError":true,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"printIntrospectionDiff":false},"preview":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"production":{"allow404Images":true,"allow401Images":true,"hardCacheMediaFiles":false},"html":{"useGatsbyImage":true,"createStaticFiles":true,"fallbackImageMaxWidth":840,"imageMaxWidth":null,"imageQuality":70,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"schema":{"perPage":50,"timeout":1800000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","requestConcurrency":15,"previewRequestConcurrency":5},"excludeFieldNames":["blocksJSON","saveContent"],"type":{"Comment":{"limit":0},"User":{"limit":0},"UserRole":{"limit":0},"PostFormat":{"limit":0},"Tag":{"limit":0},"ReusableBlock":{"limit":0},"BlockEditorPreview":{"limit":0},"Category":{"limit":null},"Post":{"limit":null},"MediaItem":{"localFile":{"excludeByMimeTypes":["image/tiff"],"maxFileSizeBytes":15728640,"requestConcurrency":100},"excludeFieldNames":["contentNodes","seo","ancestors","author","template","lastEditedBy","authorDatabaseId","authorId","contentTypeName","dateGmt","desiredSlug","enclosure","isContentNode","isTermNode","modified","modifiedGmt","parentDatabaseId","parentId","parent","children"],"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Shizuko Yoshikawa und Josef Müller-Brockmann Stiftung","short_name":"SYJMB Foundation","start_url":"/","background_color":"#FFFFFF","theme_color":"#000000","display":"browser","theme_color_in_head":false,"icon":"src/assets/images/icon.png","include_favicon":false,"legacy":false,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"1aa8f209454f72a9d48a92fa47b4ea45"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mailgo/gatsby-browser.js'),
      options: {"plugins":[],"mailgoConfig":{"showFooter":false,"actions":{"whatsapp":false}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
