/*
import "./src/assets/styles/wp-block-library.css"
import "./src/assets/styles/global.css"
import "./src/assets/styles/style.css"
import "./src/assets/styles/style-new.css"
// import "./src/assets/styles/style-rtl.css"
import "./src/assets/styles/print.css"
// import "./src/assets/styles/style-wp-head.css"
*/

/* scrolling behavior on navigation [WORKING!] */

const transitionDelay = 50

// single post with accordion-archive: look for position of active post
const scrollToActivePost = (currentPosition = [0, 0]) => {
  // get active post
  const thisPost = document.getElementsByClassName("post-active")[0]

  // decide whether to return saved position or position of active post
  if (thisPost !== undefined) {
    // scroll to active post after a short pause
    window.setTimeout(() => {
      const thisPost2 = document.getElementsByClassName("post-active")[0]

      // window.scrollTo(0, thisPost2.getBoundingClientRect().top)
      window.scrollBy(0, thisPost2.getBoundingClientRect().top)
    }, transitionDelay)
  } else {
    // return saved position
    // console.log(currentPosition)
    window.scrollTo(...currentPosition)
  }
}

exports.onClientEntry = () => {
  window.onload = () => {
    // scroll
    scrollToActivePost()
  }
}

exports.shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps,
  getSavedScrollPosition,
}) => {
  // DEFAULT SCROLL BEHAVIOUR
  // return true

  // get saved position
  const currentPosition = getSavedScrollPosition(location)

  // check #GatsbyBody for accordion
  const body = document.getElementById("GatsbyBody")
  // console.log(body)

  if (body.classList.contains("category")) {
    // category: look for previous position to enable closing of accordion

    // get previous position
    if (prevRouterProps) {
      const prevPosition = getSavedScrollPosition(prevRouterProps.location)
      // console.log(prevRouterProps.location.href, prevPosition)
      window.scrollTo(...(prevPosition || [0, 0]))
    }
  } else if (body.classList.contains("archive-accordion")) {
    scrollToActivePost(currentPosition)
  }

  // return currentPosition
  return false
}
